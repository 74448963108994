import React from 'react';

import { MediumHeaderTypo, BasicParagraphTypo, AllCapsSubTypo } from '../commonStyles/typoTheme';
import { MiddleContainer } from '../commonStyles/Containers';
import { TextContainerCenter } from '../commonStyles/TextStyles';
import { SectionLastCaseStudyMargin } from '../commonStyles/SectionsStyle';
import { CenteredButton } from '../commonStyles/ButtonStyles';

import Button from '../components/Button';

class SectionLastCaseStudy extends React.PureComponent {

  render() {

    return (
      <SectionLastCaseStudyMargin>
        <MiddleContainer>
          <TextContainerCenter>
            <MediumHeaderTypo>
              Need something similar?
            </MediumHeaderTypo>
            <BasicParagraphTypo>
              Border3px is a digital studio specializing in design and development. We can help transform your business by creating new or modyfying existing identities, products and customer experiences.
            </BasicParagraphTypo>
            <AllCapsSubTypo>
              Tell us more about your project:
            </AllCapsSubTypo>
          </TextContainerCenter>
          <CenteredButton>
            <Button name={'bcc@border3px.com'} href={'mailto:bcc@border3px.com'}>
            </Button>
          </CenteredButton>
        </MiddleContainer>
      </SectionLastCaseStudyMargin>
    );
  }
}

export default SectionLastCaseStudy;
