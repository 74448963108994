import styled from "styled-components";

export const ImageCaseStudyContainer = styled.div`
  display: block;
  position: relative;
  width: ${ props => props.isSmall ? 'calc((100% / 3) * 2)' : '100%'  };
  margin-left: ${ props => props.isSmall ? 'calc(100% / 3)' : '0'  };
  background-image: ${props => props.bgImg};
  background-size: cover;
  background-position: center;
`;
