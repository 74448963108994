import React from 'react';

import { SectionImageCaseStudyMargin } from '../commonStyles/SectionsStyle';

import { ImageCaseStudyContainer } from './styles/CaseStudyImagesStyle'



class SectionImageCaseStudy extends React.PureComponent {

  render() {

    const {
      bgImg,
      isSmall
    }  = this.props;

    return (
      <SectionImageCaseStudyMargin>
        <ImageCaseStudyContainer isSmall={isSmall}>
          <img
            src={bgImg}
            width={'100%'}
            alt={''}
          />
        </ImageCaseStudyContainer>
      </SectionImageCaseStudyMargin>
    );
  }
}

export default SectionImageCaseStudy;
