import React from 'react';
import { SectionImageCaseStudyMargin } from '../commonStyles/SectionsStyle';

import { ImgTileStyle, TileImagesContainer } from './styles/ImagesTilesStyle';

class SectionImagesTiles extends React.PureComponent {

  constructor() {
    super();

    this.renderImage = this.renderImage.bind(this);
  }

  renderImage(imageTile) {
    const HowManyImages = this.props.myArray.length;
    const myMargin = '1rem';
    return (
      <ImgTileStyle
        key={imageTile.id}
        imgWidth={'calc((100% / '+ HowManyImages +' ) - ' + myMargin + ')'}
        myMargin = {myMargin}
        backgroundColor = {imageTile.backgroundColor}
      >
      { imageTile.isVideo === true ?
        (
          <video width={'100%'} height={'100%'} autoPlay={true} loop={true} muted={true}>
            <source src={imageTile.videoSrc} type={'video/mp4'} />
          </video>
        ) :
        (
          <img
              src={imageTile.src}
              width={'100%'}
              alt={''}
            />
        )
      }

      </ImgTileStyle>
    );
  }

  render() {
    return (
      <SectionImageCaseStudyMargin>
        <TileImagesContainer>
         {this.props.myArray.map(imageTile => this.renderImage(imageTile))}
        </TileImagesContainer>
      </SectionImageCaseStudyMargin>
    );
  }
}

export default SectionImagesTiles;
