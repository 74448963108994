import styled from "styled-components";

export const ImgTileStyle = styled.div`
  display: inline-block;
  position: relative;
  margin-right: ${ props => props.myMargin };
  width: ${ props => props.imgWidth ? props.imgWidth : '10rem'  };
  background: ${ props => props.bgImg };
  background-size: contain;
  background-repeat: no-repeat;
  background-color:  ${ props => props.backgroundColor ? props.backgroundColor : 'transparent'  };
  float: left;

  :last-child {
    margin-right: 0;
  }
`;

export const TileImagesContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;
