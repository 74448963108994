import React from 'react';
import {ThemeProvider} from 'styled-components';

import themes from '../commonStyles/themes';
import { MainContainer, ThemeContainer } from '../commonStyles/Containers';

import AllFixedElements from '../components/AllFixedElements';

import SEO from '../components/seo';

import SectionInfoCaseStudy from '../componentsCaseStudy/SectionInfoCaseStudy';
import SectionImageCaseStudy from '../componentsCaseStudy/SectionImageCaseStudy';
import SectionImagesTiles from '../componentsCaseStudy/SectionImagesTiles';
import SectionLastCaseStudy from '../componentsCaseStudy/SectionLastCaseStudy'

import SectionFooter from '../components/SectionFooter';
import GlobalStyle from '../components/layoutCss';

import StillLifesContent from '../data/StillLifesContent';


class StillLifesCaseStudy extends React.PureComponent {

  render() {
    return (
      <>
        <GlobalStyle />
        <ThemeProvider theme={themes.themeMain}>
          <ThemeContainer>
          <>
            <SEO
              title='Still Lifes'
              path={this.props.path}
            />
            <AllFixedElements
              isCaseStudy={true}
            />
            <MainContainer>
                <SectionInfoCaseStudy
                  topdesc1={StillLifesContent.topdesc1}
                  topdesc2={StillLifesContent.topdesc2}
                  projectName={StillLifesContent.projectName}
                  projectCategories1={StillLifesContent.projectCategories1}
                  projectCategories2={StillLifesContent.projectCategories2}
                  projectDeliverables1={StillLifesContent.projectDeliverables1}
                  projectDeliverables2 ={StillLifesContent.projectDeliverables2}
                />
                <SectionImageCaseStudy bgImg={StillLifesContent.HeadImage}/>

                {/*----------------------------3d----------------------------*/}

                <SectionImageCaseStudy bgImg={StillLifesContent.Image1} />
                <SectionImageCaseStudy bgImg={StillLifesContent.Image2} />
                <SectionImagesTiles myArray={StillLifesContent.Gallery1}/>

                <SectionLastCaseStudy />
                <SectionFooter />
            </MainContainer>
          </>
        </ThemeContainer>
        </ThemeProvider>
      </>
    );
  }
}

export default StillLifesCaseStudy;
