import alien from '../images/projects/3d/alien.jpg';
import mochi from '../images/projects/3d/mochi.jpg';
import museum from '../images/projects/3d/museum.jpg';
import peach from '../images/projects/3d/peach.jpg';
import untitled8 from '../images/projects/3d/untitled8.jpg';

export const GalleryStillLifes = [
  { id: 1, src: alien },
  { id: 2, src: mochi }
];


export default {
  HeadImage: untitled8,
  projectName: '3d still lifes',
  projectCategories1:'Visuals',
  projectCategories2:'Art direction',
  projectDeliverables1:'3d visuals',
  projectDeliverables2:'',
  topdesc1: 'Contemporary 3d visuals, created in Blender.',
  topdesc2: '',
  Image1: museum,
  Image2: peach,
  Gallery1: GalleryStillLifes,
};
