import React from 'react';

import { SectionInfoCaseStudyMargin } from '../commonStyles/SectionsStyle';
import { MediumHeaderTypo, SubTypo, BasicParagraphTypo, AllCapsSubTypo } from '../commonStyles/typoTheme';
import { MiddleContainer } from '../commonStyles/Containers';
import { TextContainerColumns, SmallColumnsContainer, TextColumn } from '../commonStyles/TextStyles';


class SectionInfoCaseStudy extends React.PureComponent {
  render() {

    const {
      projectName,
      projectCategories1,
      projectCategories2,
      projectDeliverables1,
      projectDeliverables2,
      topdesc1,
      topdesc2,
      hasWebsite,
      websiteLink,
      websiteName
    }  = this.props;

    return (
      <SectionInfoCaseStudyMargin>
        <MiddleContainer>
          <TextContainerColumns>
            <TextColumn hasPaddingRight={true}>
              <MediumHeaderTypo>
                {projectName}
              </MediumHeaderTypo>
              <BasicParagraphTypo>
                {topdesc1}{topdesc2}
              </BasicParagraphTypo>
            </TextColumn>
            <SmallColumnsContainer>

            { hasWebsite ?
              (
              <TextColumn isLargeColumn={true} hasBottomMargin={true}>
                <AllCapsSubTypo>
                  visit the website
                </AllCapsSubTypo>
                <SubTypo>
                  <a href={websiteLink}  target={'_target'}>{websiteName}</a>
                </SubTypo>
              </TextColumn>
            ) : '' }

              <TextColumn isLargeColumn={true} hasBottomMargin={true}>
              <AllCapsSubTypo>
                Categories
              </AllCapsSubTypo>
              <SubTypo>
                {projectCategories1}
              </SubTypo>
              <SubTypo>
                {projectCategories2}
              </SubTypo>
              </TextColumn>
              <TextColumn isLargeColumn={true}>
                <AllCapsSubTypo>
                  We delivered
                </AllCapsSubTypo>
                <SubTypo>
                  {projectDeliverables1}
                </SubTypo>
                <SubTypo>
                  {projectDeliverables2}
                </SubTypo>
              </TextColumn>
            </SmallColumnsContainer>
          </TextContainerColumns>
        </MiddleContainer>
      </SectionInfoCaseStudyMargin>
    );
  }
}

export default SectionInfoCaseStudy;
